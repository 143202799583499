import React from "react";
import Banner from "../home/banner/banner";
import "../home/homepage.css";

function Homepage() {
  return (
    <div className="home-container">
  

      <Banner />
     
    </div>
  );
}

export default Homepage;
