import React from "react";
import Homepage from "./components/home/homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App-container">
      <BrowserRouter>
       
          <Routes>
            <Route path="/" element={<Homepage />} />   
          </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
